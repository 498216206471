/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, arrayOf, shape, string, func,
    element,
} from 'prop-types';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Grid } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

const styles = () => ({
    chipsContainer: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '8px',
    },
    searchChip: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F5F5F5',
        padding: '8px 12px',
        borderRadius: '30px',
        fontSize: '13px',
        whiteSpace: 'nowrap',
        fontWeight: 'normal',
        '& a': {
            textDecoration: 'none',
            color: '#000000',
            fontSize: '13px',
            fontWeight: 'normal',
        },
    },
    nameBase: {
        gap: '6px',
        maxWidth: '220px',
    },
    preIcon: {
        lineHeight: '9px',
    },
    titleText: {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    removeIcon: {
        height: '16px',
        width: '16px',
        color: '#2F363D',
    },
    removeButton: {
        border: 'none',
        padding: 0,
        margin: '0px 0px 0px 12px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        lineHeight: '9px',
    },
});

const SearchChipsUI = ({
    classes, chipDetails, id, preIcon,
}) => (
    <div className={classes.chipsContainer}>
        {chipDetails.map((data) => (
            <span className={classes.searchChip} key={`${id}-${data.inputString}`}>
                <Link
                    tabIndex="0"
                    to={data.link}
                    onClick={data.onClick}
                >
                    <Grid className={classes.nameBase} container direction="row" alignItems="center">
                        {preIcon && <Grid item className={classes.preIcon}>{preIcon}</Grid>}
                        <Grid item className={classes.titleText}>{ReactHtmlParser(data.name)}</Grid>
                    </Grid>
                </Link>
                {data?.onRemove && (
                    <button aria-label={`Remove ${data.name}`} className={classes.removeButton} type="button" onClick={data.onRemove}>
                        <Close className={classes.removeIcon} />
                    </button>
                )}
            </span>
        ))}
    </div>
);

SearchChipsUI.propTypes = {
    classes: object.isRequired,
    chipDetails: arrayOf(shape({
        link: string,
        inputString: string,
        name: string,
        onClick: func,
        onRemove: func,
    })).isRequired,
    id: string.isRequired,
    preIcon: element,
};

SearchChipsUI.defaultProps = {
    preIcon: null,
};
export default withStyles(styles)(SearchChipsUI);
