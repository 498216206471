/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { useState, useCallback } from 'react';

// Limit the array to the last 6 searches
const MAX_RECENT_SEARCHES = 6;
const STORAGE_KEY = 'recentlySearched';

const useRecentlySearched = () => {
    // Get the current value from localStorage directly before initializing state
    const getStoredSearches = () => {
        let savedSearches = [];
        if (typeof window !== 'undefined' && window.localStorage) {
            if (window.localStorage.getItem(STORAGE_KEY)) {
                savedSearches = JSON.parse(window.localStorage.getItem(STORAGE_KEY));
            }
        }
        return savedSearches;
    };

    const [recentlySearched, setRecentlySearched] = useState(getStoredSearches());

    const storeSearches = (searches) => {
        if (typeof window !== 'undefined' && window.localStorage) {
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify(searches));
        }
    };

    // using useCallback to ensure function references remain stable
    const addRecentlySearched = useCallback(({ searchTerm, value }) => {
        const currentSearches = getStoredSearches();
        const filteredSearches = currentSearches.filter((data) => data.value !== value);
        const updatedSearches = [{ searchTerm, value }, ...filteredSearches].slice(0, MAX_RECENT_SEARCHES);
        setRecentlySearched(updatedSearches);
        storeSearches(updatedSearches);
        return updatedSearches;
    }, []);

    const removeRecentlySearched = useCallback((value) => {
        const currentSearches = getStoredSearches();
        const updatedSearches = currentSearches.filter((data) => data.value !== value);
        setRecentlySearched(updatedSearches);
        storeSearches(updatedSearches);
        return updatedSearches;
    }, []);

    return {
        recentlySearched,
        addRecentlySearched,
        removeRecentlySearched,
    };
};

export default useRecentlySearched;
