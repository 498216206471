/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect, useRef } from 'react';
import {
    object, string, func, shape, arrayOf, number, bool,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Product from './Product';
import SearchBrandFilter from './SearchBrandFilter';
import SearchChipsUI from './SearchChipsUI';
import useRecentlySearched from '../../../../helpers/useRecentlySearched';
import PopularSearches from './PopularSearches';
import SearchTrendingProducts from './SearchTrendingProducts';
import SearchRecentlyViewed from './SearchRecentlyViewed';

const styles = (theme) => ({
    phraseSuggestions: {
        listStyle: 'none',
        paddingLeft: '10px',
        margin: '0px',
        '& li': {
            padding: '5px 0px',
            '& a': {
                textDecoration: 'none',
                color: theme.palette.cms?.autoSuggestPhrase || '#3c3c35',
                fontSize: '15px',
                padding: '5',
                fontWeight: 'bold',
            },
            '& span': {
                fontWeight: 'normal',
            },
        },
    },
    autoSearchResultContainer: {
        position: 'absolute',
        zIndex: '25',
        background: theme.palette.cms?.autoSuggestBackground || '#fff',
        top: '45px',
        left: 30,
        width: '100%',
        border: `1px solid ${theme.palette.cms?.autoSuggestBorder || '#d8d8d8'}`,
        borderTop: 'none',
        borderBottomRightRadius: '2px',
        borderBottomLeftRadius: '2px',
        boxShadow: '0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23)',
        maxHeight: 'calc(100vh - 102px)',
        overflowY: 'auto',
    },
    autoSearchResultContainerV2: {
        position: 'absolute',
        zIndex: '25',
        background: '#fff',
        top: '46px',
        left: 0,
        width: '100%',
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
        paddingBottom: '6px',
        boxShadow: '0px 5px 6px 0px #00000020',
        maxHeight: 'calc(100vh - 92px)',
        overflowY: 'auto',
        [theme.breakpoints.down(1024)]: {
            top: '42px',
            boxShadow: '0px 4px 4px 0px #00000020',
            borderBottomRightRadius: '0px',
            borderBottomLeftRadius: '0px',
        },
    },
    productListSuggestions: {
        listStyle: 'none',
        paddingLeft: '10px',
        margin: '3px 0',
        '& li': {
            display: 'inline-block',
            minHeight: '80px',
            maxHeight: '80px',
            width: '100%',
            marginBottom: '0px',
            '& a': {
                padding: '4px 0',
                marginBottom: '10px',
                color: theme.palette.cms?.autoSuggestProductList || '#3c3c35',
                textDecoration: 'none',
                wordWrap: 'break-word',
                display: 'block',
                fontSize: '14px',
                '& img': {
                    float: 'left',
                    width: 'calc(52px + 12px)',
                    paddingRight: '12px',
                    [theme.breakpoints.down(700)]: {
                        padding: '0 12px 40px 0',
                    },
                    display: 'block',
                },
                '& span': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    [theme.breakpoints.down(700)]: {
                        whiteSpace: 'normal',
                    },
                    display: 'block',
                },
            },
            '& span': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                [theme.breakpoints.down(700)]: {
                    whiteSpace: 'normal',
                },
                display: 'block',
            },
        },
    },
    thumbName: {
        padding: '5px 0',
    },
    thumbPrice: {
        padding: '5px 0 10px',
        fontWeight: 600,
    },
    phraseBorderRight: {
        borderRight: `${'1px solid'} ${theme.palette.cms?.autoSuggestBorderColor || '#d8d8d8'}`,
        [theme.breakpoints.down('xs')]: {
            borderRight: 0,
        },
    },
    menuItem: {
        [theme.breakpoints.down(600)]: {
            width: '90%',
        },
    },
    suggestionBase: {
        borderTop: '1px solid #E9E9E9',
    },
    historyIcon: {
        height: '18px',
        width: '18px',
        color: '#2F363D',
    },
    searchMenuBase: {
        padding: '8px 16px',
    },
    searchMenuTitle: {
        fontSize: '14px',
        fontWeight: 700,
        marginBottom: '12px',
    },
});
const getPrice = (priceList, type = 'sale') => {
    let price = null;
    const priceObject = priceList.find((itemPrice) => itemPrice.type.toLowerCase() === type);
    if (priceObject) {
        price = priceObject.value;
    }
    return price;
};

const suggestionListItems = (phrases, value, clearSearch, track, concatSearchTerm, cleanTerm, classes, addRecentlySearched, trackExperiment) => {
    const suggestionList = [];
    let response = null;
    const inputVal = value.toLowerCase();
    if (phrases?.length) {
        phrases.forEach((phrase) => {
            const data = {};
            data.name = phrase.toLowerCase().replace(inputVal, `<span>${inputVal}</span>`);
            data.inputString = phrase.toLowerCase();
            data.link = concatSearchTerm(cleanTerm(phrase.toLowerCase())?.replace(/\s+/g, '+'), phrase); // replacing spaces with +
            suggestionList.push(data);
        });
    }
    if (suggestionList.length) {
        response = suggestionList.map((data) => (
            <li
                key={data.link}
                role="menuitem"
                className={classes.menuItem}
            >
                <Link
                    tabIndex="0"
                    to={`/searchterm/${data.link}`}
                    onClick={() => {
                        clearSearch(true, data.inputString);
                        addRecentlySearched({ searchTerm: data.inputString, value: cleanTerm(data.inputString) });
                        trackExperiment('suggestion');
                        track({
                            eventCategory: 'Site Search',
                            eventAction: data.inputString,
                            eventLabel: 'SUGGESTED | <<pageType>>',
                            searchVariety: 'Suggested',
                            searchPhrase: inputVal,
                            eventName: 'search',
                        });
                    }}

                >
                    {ReactHtmlParser(data.name)}
                </Link>
            </li>
        ));
    }
    return response;
};

const recentlySearchedList = ({
    searchPhrase, phrases, clearSearch, concatSearchTerm, cleanTerm, classes,
    removeRecentlySearched, addRecentlySearched, trackExperiment, track,
}) => {
    const chipDetails = [];
    phrases.forEach(({ searchTerm, value }) => {
        const data = {};
        const searchValue = value.toLowerCase();
        data.name = searchTerm;
        data.inputString = searchValue;
        data.link = `/searchterm/${concatSearchTerm(cleanTerm(searchValue)?.replace(/\s+/g, '+'), value)}`; // replacing spaces with +
        data.onClick = () => {
            clearSearch(true, searchValue);
            // to bring recently searched item to beginning of the list
            addRecentlySearched({ searchTerm, value });
            trackExperiment('product suggestion - recently searched');
            track({
                eventCategory: 'Site Search',
                eventAction: searchValue,
                eventLabel: 'RECENTLY SEARCHED | <<pageType>>',
                searchVariety: 'Recently Searched',
                searchPhrase,
                eventName: 'search',
            });
        };
        data.onRemove = () => removeRecentlySearched(value);
        chipDetails.push(data);
    });
    return (
        <SearchChipsUI
            chipDetails={chipDetails}
            id="recently-searched"
            preIcon={<HistoryIcon className={classes.historyIcon} />}
        />
    );
};

const SearchDropDown = ({
    phrase, suggestions, classes, clearSearch, track, trackExperiment, concatSearchTerm, cleanTerm, handleSearchSubmit,
    isSearchBarBrandFilterEnabled, includeSiblingBrandSearch, setIncludeSiblingBrandSearch, searchUiTypeAheadEnabled,
}) => {
    const htmlRef =  useRef([]);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const { recentlySearched, removeRecentlySearched, addRecentlySearched } = useRecentlySearched();

    useEffect(() => {
        const listItems = htmlRef?.current?.[0]?.children;
        const listProducts =  htmlRef?.current?.[1]?.children;
        const productListLength = suggestions?.products?.length || 0;
        const listItemsLength = suggestions?.phrases?.length || 0;
        let i = -1;
        let index = -1;
        const listFunc = (event) => {
            if (event.keyCode === 38 && (i > 0 || index > 0)) {
                if (index > 0) {
                    i = listItemsLength;
                    index -= 1;
                    listProducts?.[index]?.children?.[0].focus();
                } else {
                    i -= 1;
                    listItems?.[i]?.children?.[0].focus();
                }
                event.preventDefault();
                event.stopPropagation();
            } else if (event.keyCode === 40) {
                if (i < listItemsLength  && i !== listItemsLength - 1) {
                    i += 1;
                    index = -1;
                    listItems?.[i]?.children?.[0].focus();
                } else {
                    if (productListLength - 1 === index) {
                        i = -1;
                        index -= 1;
                    }
                    index += 1;
                    listProducts?.[index]?.children?.[0].focus();
                }
                event.preventDefault();
                event.stopPropagation();
            }
        };
        if (typeof document !== 'undefined' && (listItems || listProducts)) {
            document.addEventListener('keydown', listFunc, false);
        }
        return () => {
            document.removeEventListener('keydown', listFunc);
        };
    }, []);

    if (!isSearchBarBrandFilterEnabled && (!suggestions?.products || !suggestions?.phrases)) {
        return <></>;
    }

    return (
        <>
            <div className={isSearchBarBrandFilterEnabled ? classes.autoSearchResultContainerV2 : classes.autoSearchResultContainer}>
                {isSearchBarBrandFilterEnabled && <SearchBrandFilter includeSiblingBrandSearch={includeSiblingBrandSearch} setIncludeSiblingBrandSearch={setIncludeSiblingBrandSearch} handleSearchSubmit={handleSearchSubmit} />}
                {(suggestions?.products?.length > 0 || suggestions?.phrases?.length > 0) ? (
                    <Grid container className={`${isSearchBarBrandFilterEnabled ? classes.suggestionBase : ''}`}>
                        {suggestions?.phrases?.length > 0 && (
                            <Grid item sm={suggestions?.products?.length > 0 ? 4 : 12} xs={12} className={suggestions?.products?.length > 0 ? classes.phraseBorderRight : ''}>
                                <ul
                                    ref={(element) => {
                                        htmlRef.current[0] = element;
                                    }}
                                    className={classes.phraseSuggestions}
                                    id="suggestion"
                                >
                                    {suggestionListItems(suggestions.phrases, phrase, clearSearch, track, concatSearchTerm, cleanTerm, classes, addRecentlySearched, trackExperiment)}
                                </ul>
                            </Grid>
                        )}
                        {(suggestions?.products?.length > 0) && (
                            <Grid item sm={8} xs={12}>
                                <ul
                                    ref={(element) => {
                                        htmlRef.current[1] = element;
                                    }}
                                    className={classes.productListSuggestions}
                                >
                                    {suggestions?.products?.map((data) => (
                                        <li
                                            key={data?.seo?.url}
                                            role="menuitem"
                                        >
                                            <Product
                                                data={data}
                                                phrase={phrase}
                                                track={track}
                                                trackExperiment={trackExperiment}
                                                concatSearchTerm={concatSearchTerm}
                                                clearSearch={clearSearch}
                                                getPrice={getPrice}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </Grid>
                        )}

                    </Grid>
                ) : (
                    <>
                        {/* enabling only for isSearchBarBrandFilterEnabled versions */}
                        {isSearchBarBrandFilterEnabled && searchUiTypeAheadEnabled && phrase?.length < 1
                            ? (
                                <>
                                    {recentlySearched?.length > 0 && (
                                        <div className={classes.searchMenuBase} data-testid="recently-searched">
                                            <div className={classes.searchMenuTitle}>Recently Searched</div>
                                            {recentlySearchedList({
                                                searchPhrase: phrase,
                                                phrases: isMobile ? recentlySearched.slice(0, 3) : recentlySearched,
                                                clearSearch,
                                                concatSearchTerm,
                                                cleanTerm,
                                                classes,
                                                removeRecentlySearched,
                                                addRecentlySearched,
                                                trackExperiment,
                                                track,
                                            })}
                                        </div>
                                    )}
                                    <SearchRecentlyViewed
                                        searchPhrase={phrase}
                                        clearSearch={clearSearch}
                                        trackExperiment={trackExperiment}
                                        track={track}
                                    />
                                    <PopularSearches
                                        searchPhrase={phrase}
                                        clearSearch={clearSearch}
                                        trackExperiment={trackExperiment}
                                        track={track}
                                        concatSearchTerm={concatSearchTerm}
                                        cleanTerm={cleanTerm}
                                    />
                                    <SearchTrendingProducts
                                        searchPhrase={phrase}
                                        clearSearch={clearSearch}
                                        trackExperiment={trackExperiment}
                                        track={track}
                                    />
                                </>
                            ) : <></>}
                    </>
                )}
            </div>
        </>
    );
};
SearchDropDown.propTypes = {
    classes: object.isRequired,
    suggestions: shape({
        codes: arrayOf(string),
        phrases: arrayOf(string),
        products: arrayOf(shape({
            name: string,
            image: shape({
                name: string,
                path: string,
            }),
            seo: shape({
                url: string,
            }),
            prices: arrayOf(
                shape({
                    type: string,
                    value: number,
                }),
            ),
        })),
    }).isRequired,
    phrase: string.isRequired,
    clearSearch: func.isRequired,
    track: func.isRequired,
    trackExperiment: func,
    concatSearchTerm: func.isRequired,
    cleanTerm: func.isRequired,
    isSearchBarBrandFilterEnabled: bool,
    includeSiblingBrandSearch: bool,
    setIncludeSiblingBrandSearch: func,
    handleSearchSubmit: func,
    searchUiTypeAheadEnabled: bool,
};

SearchDropDown.defaultProps = {
    isSearchBarBrandFilterEnabled: false,
    includeSiblingBrandSearch: false,
    setIncludeSiblingBrandSearch: () => {},
    handleSearchSubmit: () => {},
    trackExperiment: () => {},
    searchUiTypeAheadEnabled: false,
};

export default withStyles(styles)(SearchDropDown);
