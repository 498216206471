/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import mbpLogger from 'mbp-logger';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useMediaQuery } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';

import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';
import { getBrandName } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { GRAPHQL_ENV } from '../../../../gql';
import SearchChipsUI from './SearchChipsUI';

const useStyles = makeStyles(() => ({
    base: {
        padding: '8px 16px',
        width: '100%',
    },
    title: {
        fontSize: '14px',
        fontWeight: 700,
        marginBottom: '12px',
    },
    skeletonImageRoot: {
        width: '100%',
        paddingTop: '100%',
        position: 'relative',
    },
    skeletonImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        margin: 0,
        borderRadius: '4px',
    },
    imageLink: {
        display: 'block',
        width: '100%',
    },
    imageRoot: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: '100%',
        objectFit: 'cover',
        height: 'auto',
    },
}));

const FIND_TRENDING_SEARCHES = gql`
    query FindTrendingSearches($brand: String!, $environment: String!) {
        findTrendingSearches(brand: $brand, environment: $environment) {
            trendingSearches {
                phrase
                rank
            }
        }
    }`;

function PopularSearches({
    searchPhrase, track, trackExperiment, clearSearch, concatSearchTerm, cleanTerm,
}) {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const noOfProductsToShow = isMobile ? 3 : 6;

    const classes = useStyles();
    const isBot = useSelector(getIsBot);
    const brand = useSelector(getBrandName);

    if (isBot) {
        return null;
    }

    const {
        loading, error, data,
    } = useQuery(FIND_TRENDING_SEARCHES, {
        variables: {
            brand: '1800flowers',
            environment: GRAPHQL_ENV,
        },
        apolloOptions: {
            fetchPolicy: 'cache-first',
        },
    });

    if (loading) {
        return <></>;
    }

    if (error) {
        mbpLogger.logError({
            function: 'PopularSearches.js',
            message: 'Error loading data for trending searches',
            appName: process.env.npm_package_name,
            module: 'PopularSearches',
            error,
        });
        return null;
    }

    if (!data?.findTrendingSearches?.trendingSearches || data?.findTrendingSearches?.trendingSearches?.length <= 0) {
        return null;
    }

    const chipDetails = [];

    data?.findTrendingSearches?.trendingSearches?.slice(0, noOfProductsToShow)
        ?.forEach(({ phrase }) => {
            const details = {};
            const searchValue = phrase.toLowerCase();
            details.name = phrase;
            details.inputString = searchValue;
            details.link = `/searchterm/${concatSearchTerm(cleanTerm(searchValue)?.replace(/\s+/g, '+'), phrase)}`; // replacing spaces with +
            details.onClick = () => {
                clearSearch(true, searchValue);
                trackExperiment('product suggestion - popular searches');
                track({
                    eventCategory: 'Site Search',
                    eventAction: searchValue,
                    eventLabel: 'POPULAR SEARCH | <<pageType>>',
                    searchVariety: 'Popular Searches',
                    searchPhrase,
                    eventName: 'search',
                });
            };
            chipDetails.push(details);
        });

    return chipDetails?.length > 0
        ? (
            <div className={classes.base}>
                <div className={classes.title}>
                    Popular Searches
                </div>
                <SearchChipsUI
                    chipDetails={chipDetails}
                    id="popular-searches"
                />
            </div>
        )
        : <></>;
}

PopularSearches.propTypes = {
    searchPhrase: PropTypes.string,
    track: PropTypes.func.isRequired,
    trackExperiment: PropTypes.func,
    clearSearch: PropTypes.func.isRequired,
    concatSearchTerm: PropTypes.func.isRequired,
    cleanTerm: PropTypes.func.isRequired,
};

PopularSearches.defaultProps = {
    searchPhrase: '',
    trackExperiment: () => {},
};

export default React.memo(PopularSearches);
