/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';

/**
 * @description Finds recently viewed products based on a customers EID and browsing behavior from the clickstream
 * @param { string } enterpriseId unique identifier for a customer
 * @param { string } environment graphql env
 * @param { string } brand
 */
const findRecentlyViewedProducts = () => (
    gql`query recentlyViewedProducts (
        $brand: String!
        $environment: String!
        $user: UserSF!
        $isHoldoutEligible: Boolean!
    ) {
        recentlyViewed(
            brand: $brand
            environment: $environment
            user: $user
            isHoldoutEligible: $isHoldoutEligible
        ) {
            products {
                skuPriceRange {
                  sale {
                    value
                  }
                  retail {
                    value
                  }
                }
                partNumber
                name
                image {
                  name
                  path
                }
                seo {
                  url
                }
              }
              isHoldout
        }
    }`
);

export default findRecentlyViewedProducts;
