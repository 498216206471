/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import mbpLogger from 'mbp-logger';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';
import GenericSkeleton from '../../GraphqlSkeletonComponents/GenericSkeleton';
import { getCurrentPageType } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';
import useSalesforceProductRecommendationQuery from '../../../../helpers/Personalization/useSalesforceProductRecommendationQuery';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import ConnectedDesktopSimpleProduct from '../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/DesktopSimpleProduct';
import useRecentlySearched from '../../../../helpers/useRecentlySearched';
import { getBaseCode } from '../../../../helpers/tracking/product/dataLayerProductHelpers';

const useStyles = makeStyles(() => ({
    base: {
        padding: '8px 16px',
        width: '100%',
    },
    title: {
        fontSize: '14px',
        fontWeight: 700,
        marginBottom: '12px',
    },
    productCard: {
        width: '100%',
        '& a div': {
            marginBottom: 0,
        },
        '& a p': {
            lineHeight: '1.25',
        },
        '& a p span': {
            fontSize: '13px',
            fontWeight: 'normal',
            whiteSpace: 'normal',
        },
        '& a p div span': {
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: '2',
        },
        '& a p div div': {
            marginTop: '4px',
            maxWidth: '50px',
        },
    },
    skeletonRoot: {
        width: '100%',
        paddingTop: '150%',
        position: 'relative',
    },
    skeletonImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '74%',
        margin: 0,
        borderRadius: '4px',
    },
    skeletonTitle: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        margin: 0,
        width: '100%',
        height: '24%',
        borderRadius: '4px',
    },
}));

function SearchTrendingProducts({
    searchPhrase, track, trackExperiment, clearSearch,
}) {
    const noOfProductsToShow = 6;

    const classes = useStyles();
    const isBot = useSelector(getIsBot);
    const pageType = useSelector(getCurrentPageType);
    const { addRecentlySearched } = useRecentlySearched();

    if (isBot) {
        return null;
    }

    const {
        loading, error, data, variables,
    } = useSalesforceProductRecommendationQuery({
        queryName: 'TrendingSearchProducts',
        campaignFeature: 'Trending Search Products',
        targeting: {
            pageType,
        },
        optInHoldoutParticipation: true,
        resolveIdentity: true,
        filterByZipCode: true,
        apolloOptions: {
            fetchPolicy: 'cache-first',
        },
    });

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: data?.productRecommendationsSFMulti?.[0],
        isFireImpression: true,
        page: { type: 'search' },
    });

    if (loading || variables?.skip) {
        return (
            <div className={classes.base}>
                <div className={classes.title}>
                    Trending Products
                </div>
                <Grid container spacing={2}>
                    {Array.from({ length: noOfProductsToShow }, (_, index) => index).map((id) => (
                        <Grid item xs={4} sm={2}>
                            <div container className={classes.skeletonRoot}>
                                <GenericSkeleton className={classes.skeletonImage} id={`skeleton-trending-products-img-${id}`} />
                                <GenericSkeleton className={classes.skeletonTitle} id={`skeleton-trending-products-title-${id}`} />
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }

    if (error) {
        mbpLogger.logError({
            function: 'SearchTrendingProducts.js',
            message: 'Error loading data from saleforce api',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return null;
    }

    const productClickCallback = (name, partNumber) => {
        track({
            eventCategory: 'Site Search',
            eventAction: 'trending product click',
            eventLabel: `TRENDING PRODUCT | ${partNumber} | ${ReactHtmlParser(name)}`,
            searchVariety: 'Trending product',
            eventName: 'search',
            searchPhrase,
            searchProductId: getBaseCode(partNumber),
        });
        addRecentlySearched({ searchTerm: name, value: partNumber });
        trackExperiment('product suggestion - trending products');
        clearSearch();
        trackClickThrough(partNumber);
    };

    if (data?.productRecommendationsSFMulti?.[0]?.products?.length > 0) {
        const products = data.productRecommendationsSFMulti?.[0]?.products?.slice(0, noOfProductsToShow);
        return (
            <div className={classes.base}>
                <div className={classes.title}>
                    Trending Products
                </div>
                <Grid container spacing={2}>
                    {products?.map((product) => {
                        const {
                            name, image, seo: { url }, skuPriceRange, availability, partNumber, brandId,
                        } = product;
                        const link = url || '';
                        return (
                            <Grid item xs={4} sm={2}>
                                <div className={classes.productCard}>
                                    <ConnectedDesktopSimpleProduct
                                        name={name}
                                        url={link}
                                        image={image}
                                        skuPriceRange={skuPriceRange}
                                        availability={availability}
                                        partNumber={partNumber}
                                        showSimpleProductRedesignAbTest={false}
                                        productBrand={brandId}
                                        productClickCallback={() => productClickCallback(name, partNumber)}
                                        productSkus={product.productSkus}
                                    />
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        );
    }

    return null;
}

SearchTrendingProducts.propTypes = {
    searchPhrase: PropTypes.string,
    track: PropTypes.func.isRequired,
    trackExperiment: PropTypes.func,
    clearSearch: PropTypes.func.isRequired,
};

SearchTrendingProducts.defaultProps = {
    searchPhrase: '',
    trackExperiment: () => {},
};

export default SearchTrendingProducts;
