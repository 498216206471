/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import mbpLogger from 'mbp-logger';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useQuery } from '@apollo/client';

import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';
import { getBaseCode } from '../../../../helpers/tracking/product/dataLayerProductHelpers';
import findRecentlyViewedProducts from '../../../../gql/queries/findRecentlyViewedProducts';
import { getBrandName } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import useGetAccessTokenSafely from '../../../../gql/hooks/useGetAccessTokenSafely';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useBrowserUUID from '../../../../helpers/useBrowserUUID';
import { GRAPHQL_ENV } from '../../../../gql';

const useStyles = makeStyles(() => ({
    base: {
        padding: '8px 16px',
        width: '100%',
    },
    title: {
        fontSize: '14px',
        fontWeight: 700,
        marginBottom: '12px',
    },
    imageLink: {
        display: 'block',
        width: '100%',
    },
    imageRoot: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: '100%',
        objectFit: 'cover',
        height: 'auto',
    },
}));

function SearchRecentlyViewed({
    searchPhrase, track, trackExperiment, clearSearch,
}) {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const noOfProductsToShow = isMobile ? 3 : 6;

    const classes = useStyles();
    const isBot = useSelector(getIsBot);
    const brand = useSelector(getBrandName);
    const jwt = useGetAccessTokenSafely();
    const browserUUID = useBrowserUUID();
    const isPersonalizationHoldoutEnabled = useSelector(getFeatureFlag('is-personalization-holdout-enabled'));

    if (isBot) {
        return null;
    }

    const {
        loading, error, data,
    } = useQuery(findRecentlyViewedProducts(), {
        variables: {
            brand,
            environment: GRAPHQL_ENV,
            isHoldoutEligible: isPersonalizationHoldoutEnabled,
            user: {
                anonymousId: browserUUID,
            },
        },
        context: {
            api: 'personalizedContent',
            jwt,
        },
        skip: !jwt || !browserUUID,
        fetchPolicy: 'network-only',
    });

    if (!jwt || !browserUUID) {
        return null;
    }

    if (loading) {
        return null;
    }

    if (error) {
        mbpLogger.logError({
            function: 'SearchRecentlyViewed.js',
            message: 'Error loading data for recently viewed products',
            appName: process.env.npm_package_name,
            module: 'SearchRecentlyViewed',
            error,
        });
        return null;
    }

    const handleClick = (name, partNumber) => {
        track({
            eventCategory: 'Site Search',
            eventAction: 'recently viewed product click',
            eventLabel: `RECENTLY VIEWED | ${partNumber} | ${ReactHtmlParser(name)}`,
            searchVariety: 'Recently viewed',
            eventName: 'search',
            searchPhrase,
            searchProductId: getBaseCode(partNumber),
        });
        trackExperiment('product suggestion - recently viewed');
        clearSearch();
    };

    return data?.recentlyViewed?.products?.length > 0
        ? (
            <div className={classes.base}>
                <div className={classes.title}>
                    Recently Viewed
                </div>
                <Grid container spacing={2}>
                    {data?.recentlyViewed?.products?.slice(0, noOfProductsToShow)?.map((product) => {
                        const {
                            name, image, seo: { url }, partNumber,
                        } = product;
                        const link = url || '';
                        const imageUrl = `${image?.path}${image?.name}x.jpg?height=456&width=418&sharpen=a0.5,r1,t1&auto=webp`;
                        return (
                            <Grid item xs={4} sm={2}>
                                <Link
                                    data-testid={`${name}-${partNumber}`?.split(' ')?.join('-')?.toLowerCase()}
                                    data-test="search-recently-viewed-product"
                                    title={`${ReactHtmlParser(name)}`}
                                    tabIndex="0"
                                    to={link}
                                    className={classes.imageLink}
                                    onClick={() => handleClick(name, partNumber)}
                                    replace={!!(typeof window !== 'undefined' && window.is404)}
                                >
                                    <Grid container className={classes.imageRoot}>
                                        <Grid item>
                                            <img
                                                src={imageUrl}
                                                alt={`${ReactHtmlParser(name)}`}
                                                className={classes.image}
                                                loading="lazy"
                                            />
                                        </Grid>
                                    </Grid>
                                </Link>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        )
        : <></>;
}

SearchRecentlyViewed.propTypes = {
    searchPhrase: PropTypes.string,
    track: PropTypes.func.isRequired,
    trackExperiment: PropTypes.func,
    clearSearch: PropTypes.func.isRequired,
};

SearchRecentlyViewed.defaultProps = {
    searchPhrase: '',
    trackExperiment: () => {},
};

export default SearchRecentlyViewed;
