/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Grid, Switch, Button } from '@material-ui/core';
import { array, bool, func } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '../../../BrandTheme/Icons/SearchIcon';

const useStyles = makeStyles((theme) => ({
    description: {
        margin: '8px 4px 8px 8px',
        padding: '8px 16px',
        background: '#F5F5F5',
        borderRadius: '3px',
        [theme.breakpoints.down(1212)]: {
            flex: 1,
            padding: '4px 12px',
        },
    },
    descriptionBase: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    descriptionFullWidth: {
        flex: 1,
    },
    descriptionBaseText: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        [theme.breakpoints.down(1212)]: {
            flexDirection: 'column',
            alignItems: 'normal',
        },
        [theme.breakpoints.down(1026)]: {
            flexDirection: 'row',
            alignItems: 'flex-end',
        },
        [theme.breakpoints.down(737)]: {
            flexDirection: 'column',
            alignItems: 'normal',
        },
    },
    descriptionTitle: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#000000',
        '& sup': {
            fontSize: '10px',
        },
    },
    descriptionSiblingText: {
        fontSize: '12px',
        color: '#434343',
        marginLeft: '4px',
        [theme.breakpoints.down(1212)]: {
            marginLeft: 0,
            marginTop: '-2px',
        },
        [theme.breakpoints.down(1026)]: {
            marginLeft: '4px',
            marginTop: 0,
        },
        [theme.breakpoints.down(737)]: {
            marginLeft: 0,
            marginTop: '-2px',
        },
    },
    switchRoot: {
        width: 42,
        height: 26,
        padding: 0,
        margin: '0px 0px 0px 16px',
    },
    switchBase: {
        padding: 1,
        backgroundColor: 'transparent !important',
        '&$switchChecked': {
            transform: 'translateX(16px)',
            '& + $switchTrack': {
                backgroundColor: '#058942',
                opacity: 1,
                border: 'none',
            },
        },
        '&$switchFocusVisible $switchThumb': {
            color: '#058942',
        },
    },
    switchThumb: {
        margin: 2,
        width: 20,
        height: 20,
        backgroundColor: '#fff',
    },
    switchTrack: {
        borderRadius: 26 / 2,
        backgroundColor: 'rgba(120, 120, 128, 0.16)',
        opacity: 1,
    },
    switchChecked: {},
    switchFocusVisible: {},
    buttonBase: {
        padding: '8px 8px 8px 4px',
        [theme.breakpoints.up(1212)]: {
            flex: 1,
        },
        [theme.breakpoints.down(1026)]: {
            flex: 0.5,
        },
    },
    searchButton: {
        color: theme.palette.white,
        width: '100%',
        height: '100%',
        textTransform: 'none',
        fontSize: '14px',
        backgroundColor: '#262626',
        '-webkit-font-smoothing': 'antialiased',
        '&:hover': {
            backgroundColor: '#262626',
        },
        '&:focus': {
            outline: '1px solid #262626',
            outlineColor: '#262626',
        },
        '& svg': {
            height: '16px',
            width: '16px',
        },
        '& path': {
            fill: theme.palette.white,
        },
        [theme.breakpoints.down(1212)]: {
            width: '200px',
        },
        [theme.breakpoints.down(1084)]: {
            width: '100%',
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        [theme.breakpoints.down(1026)]: {
            width: '100%',
        },
        [theme.breakpoints.down(737)]: {
            width: '200px',
        },
        [theme.breakpoints.down(500)]: {
            width: '100%',
        },
    },
}));

const SearchBrandFilter = ({
    includeSiblingBrandSearch, setIncludeSiblingBrandSearch, handleSearchSubmit,
}) => {
    const classes = useStyles();

    const handleBrandFilter = () => {
        setIncludeSiblingBrandSearch(!includeSiblingBrandSearch);
    };

    return (
        <Grid data-testid="on-site-brand-search" container wrap="nowrap">
            <Grid item className={classes.description}>
                <div>
                    <div className={classes.descriptionBase}>
                        <div className={classes.descriptionFullWidth}>
                            <div className={classes.descriptionBaseText}>
                                <div className={classes.descriptionTitle}>+ All Celebrations<sup>&reg;</sup> Brands</div>
                                <div className={classes.descriptionSiblingText}>Include all sibling brands in search</div>
                            </div>
                        </div>
                        <div>
                            <Switch
                                focusVisibleClassName={classes.switchFocusVisible}
                                disableRipple
                                classes={{
                                    root: classes.switchRoot,
                                    switchBase: classes.switchBase,
                                    thumb: classes.switchThumb,
                                    track: classes.switchTrack,
                                    checked: classes.switchChecked,
                                }}
                                checked={includeSiblingBrandSearch}
                                onChange={handleBrandFilter}
                            />
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item className={classes.buttonBase}>
                <Button startIcon={<SearchIcon />} className={classes.searchButton} id="btn-search" data-test="hp-header-search-button" name="hpSearchButton" onClick={handleSearchSubmit} type="submit">
                    Search
                </Button>
            </Grid>
        </Grid>
    );
};

SearchBrandFilter.propTypes = {
    includeSiblingBrandSearch: array.isRequired,
    setIncludeSiblingBrandSearch: func.isRequired,
    handleSearchSubmit: func.isRequired,
};

export default SearchBrandFilter;
