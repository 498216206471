/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, func, arrayOf, shape, bool,
} from 'prop-types';
import { useQuery } from '@apollo/client';
import mbpLogger from 'mbp-logger';

import getAutoSuggest from '../../../../gql/queries/getAutoSuggest';
import SearchDropdown from './SearchDropdown';

/**
 *
 * @param {*} str take the input from search box
 * @return string
 * @example input val test"s it will return test\"s
 *  @example input val \ it will return ' '
 */
const escapeDoubleQuotes = (str) => str.replace(/"/g, '\\"');

const ElasticSearchSuggestions = ({
    track, trackExperiment, phrase, brand, clearSearch, concatSearchTerm, cleanTerm, targeting, experimentsService, targetingEnabled,
    isSearchBarBrandFilterEnabled, includeSiblingBrandSearch, setIncludeSiblingBrandSearch, handleSearchSubmit, searchUiTypeAheadEnabled,
}) => {
    let response = (
        <SearchDropdown
            cleanTerm={cleanTerm}
            concatSearchTerm={concatSearchTerm}
            track={track}
            trackExperiment={trackExperiment}
            clearSearch={clearSearch}
            phrase={phrase}
            suggestions={{}}
            isSearchBarBrandFilterEnabled={isSearchBarBrandFilterEnabled}
            includeSiblingBrandSearch={includeSiblingBrandSearch}
            setIncludeSiblingBrandSearch={setIncludeSiblingBrandSearch}
            handleSearchSubmit={handleSearchSubmit}
            searchUiTypeAheadEnabled={searchUiTypeAheadEnabled}
        />
    );
    if (!phrase && isSearchBarBrandFilterEnabled) return response;
    if (!phrase) return <></>;
    const getPhraseData = phrase.replace(/\\/g, ' ');
    const FIND_SUGGESTIONS = getAutoSuggest({ targetingEnabled, brand, phrase: encodeURIComponent(escapeDoubleQuotes(getPhraseData)) });
    const { error, data } = useQuery(FIND_SUGGESTIONS, {
        variables: { ...(targetingEnabled ? { targeting } : {}), phrase },
        context: {
            api: experimentsService ? 'experiments' : 'aggregator',
        },
    });

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            jsError: error,
            message: `Failed to fetch content data for: auto suggestions from elastic search(ElasticSearchSuggestions) on brand ${brand}`,
        });
    }

    if (data?.autoSuggest?.products?.length > 0 || data?.autoSuggest?.phrases?.length >= 0) {
        response = (
            <SearchDropdown
                cleanTerm={cleanTerm}
                concatSearchTerm={concatSearchTerm}
                track={track}
                trackExperiment={trackExperiment}
                clearSearch={clearSearch}
                phrase={phrase}
                suggestions={data.autoSuggest}
                isSearchBarBrandFilterEnabled={isSearchBarBrandFilterEnabled}
                includeSiblingBrandSearch={includeSiblingBrandSearch}
                setIncludeSiblingBrandSearch={setIncludeSiblingBrandSearch}
                handleSearchSubmit={handleSearchSubmit}
                searchUiTypeAheadEnabled={searchUiTypeAheadEnabled}
            />
        );
    }
    return response;
};

ElasticSearchSuggestions.propTypes = {
    phrase: string.isRequired,
    brand: string.isRequired,
    clearSearch: func.isRequired,
    track: func.isRequired,
    trackExperiment: func,
    concatSearchTerm: func.isRequired,
    cleanTerm: func,
    targeting: arrayOf(shape({
        key: string,
        value: string,
    })),
    experimentsService: bool,
    targetingEnabled: bool,
    isSearchBarBrandFilterEnabled: bool,
    includeSiblingBrandSearch: bool.isRequired,
    setIncludeSiblingBrandSearch: func.isRequired,
    handleSearchSubmit: func,
    searchUiTypeAheadEnabled: bool,
};

ElasticSearchSuggestions.defaultProps = {
    cleanTerm: () => { },
    trackExperiment: () => {},
    targeting: [],
    experimentsService: false,
    targetingEnabled: false,
    isSearchBarBrandFilterEnabled: false,
    handleSearchSubmit: () => {},
    searchUiTypeAheadEnabled: false,
};

export default ElasticSearchSuggestions;
